<template>
  <div class="webBar1"></div>
</template>

<script>
export default {
  name: '',
  props: {
    data: Object
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        const option = this.myChart.getOption()
        const that = this
        option.tooltip = {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'none' // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: '#11367a',
          textStyle: {
            color: '#6dd0e3',
            fontSize: 10
          },
          formatter: (params) => {
            params[1].number = that.dataSource.number
            return params[1].name + '<br>' + '数量:' + params[1].value
          }
        }
        option.yAxis[0].data = this.dataSource.data
        option.series[0].data = this.setData()
        option.series[1].name = this.dataSource.name
        option.series[1].data = this.dataSource.value
        this.myChart.setOption(option)
      }
    }
  },
  methods: {
    setData() {
      var max = Math.max.apply(null, this.dataSource.value)
      var arr = []
      for (let i = 0; i < this.dataSource.value.length; i++) {
        arr.push(max / 10)
      }
      return arr
    },
    // 网站
    setChart() {
      let option = {
        // grid: {
        //   left: '3%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true,

        //   borderColor: 'rgba(0,0,0,0)', // 设置边框颜色为透明
        //   lineStyle: {
        //     type: 'dashed', // 或者'solid'
        //     color: 'rgba(0,0,0,0)' // 设置网格线颜色为透明
        //   }
        // },
        //鼠标hover显示数据
        legend: {
          top: '20',

          textStyle: {
            color: '#fff',
            fontSize: 10
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },

          backgroundColor: '#11367a',
          textStyle: {
            color: '#6dd0e3',
            fontSize: 10
          },
          // formatter: function (params) {
          //   return (
          //     params[0].name +
          //     '<br>' +
          //     params[0].seriesName +
          //     '：' +
          //     params[0].value +
          //     '<br>' +
          //     params[1].seriesName +
          //     '：' +
          //     params[1].value +
          //     '<br>' +
          //     params[2].seriesName +
          //     '：' +
          //     params[2].value
          //   )
          // }
          formatter: function (params) {
            return (
              params[0].name +
              '<br>' +
              params
                .map(function (param) {
                  return param.seriesName + ': ' + param.value
                })
                .join('<br>')
            )
          }
        },

        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#323232' // 设置线颜色为透明
            }
          },
          type: 'value',
          // boundaryGap: [0, 0.01],
          axisLabel: {
            fontSize: 10,
            color: '#fff' // 设置水平轴字体颜色为红色
          }
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 10,
            color: '#fff' // 设置水平轴线条颜色为红色
          },

          data: ['2022', '2023', '2024']
        },
        series: [
          {
            name: '新增会员',
            type: 'bar',
            // color: '#37d7c5',
            itemStyle: {
              normal: {
                color: {
                  // 颜色线性渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgb( 4, 115, 49,0.1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgb(4, 115, 49 ,1)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                barBorderRadius: 5
              }
            },

            data: [15203, 23489, 29034]
          },
          {
            name: '续费会员',
            type: 'bar',
            // color: '#0b5ca0',
            itemStyle: {
              normal: {
                color: {
                  // 颜色线性渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgb(53, 116, 168  ,0.1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgb(53, 116, 168 ,1)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                barBorderRadius: 5
              }
            },
            data: [29325, 33438, 41000]
          },
          {
            name: '总计',
            type: 'bar',
            // color: 'rgb(255, 217, 0,1)',
            itemStyle: {
              normal: {
                color: {
                  // 颜色线性渐变
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgb(255, 217, 0,0.1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgb(255, 217, 0,1)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                barBorderRadius: 5
              }
            },

            data: [44528, 56927, 70034]
          }
        ]
      }

      this.myChart = this.$echarts(this.$el)
      this.myChart.clear()
      this.myChart.resize()

      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.webBar1 {
  float: left;
  width: 100%;
  height: 90%;
}
</style>
